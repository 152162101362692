<template>
    <div class="news_center_with_right_content">
        <div style="background: #FFFFFF;margin-top: 120px;width: 1500px;min-height: 1400px;float: left">
            <div style="width:100%;float: left;">
                <div style="height: 60px;position: relative">
                    <div style="margin-left: 10px;float: left" v-for="(item,index) in channellist" :key="index">
                        <div @click="nameType(item.nameId || item.id)" style="font-size: 20px;font-weight: 600;height: 24px;padding: 18px 20px 0 20px;cursor: pointer;text-align: left;" class="hand op sc" :style="nameId == item.id?'color:#2970FF;':''">{{item.name}}</div>
                    </div>
                    <div style="position: absolute;top: 60px;width: 1500px;"><el-divider></el-divider></div>
                </div>
                <!--  文章more  -->
                <div v-if="class_id == 1" v-for="(data,index) in ArticlesList" :key="index" style="width: calc((100% - 20px) / 2); margin: 10px 10px 10px 0;float: left">
                  <div @click="content_info(data.id)" class="hand op sc" style="width: 100%;border-radius: 5px; box-shadow: 0px 2px 8px 1px rgba(0,0,0,0.16);background: #FFFFFF; position: relative">
                    <div  v-if="data.PayStatus === 1" style="border-top: 38px solid #69BF29;border-radius:0 5px 0 0;border-left: 38px solid transparent;position:absolute;top:0;right:0;width: 0; height: 0"></div>
                     <div v-if="data.PayStatus === 1" style="position:absolute;top:4px;right:2px;color: #FFFFFF; font-size: 10px">已购</div>
                        <div style="padding: 20px; display: flex; justify-content: space-between">
                            <img class="hand op"  v-if="data.cover" :src="data.cover" style="width: 150px; height: 100px; margin-right: 12px; border-radius: 5px">
                            <div style="width: 72%">
                                <div class="hand op" style="overflow: hidden;height: 64px">
                                    <div style="height: 22px">
                                        <img src="@/assets/imgs/other/buy.png" style="width: 22px; height: 22px; margin-right: 4px;float: left" v-if="data.payAmt > 0">
                                        <span style="font-size: 16px;font-weight: 600;overflow: hidden;text-overflow: ellipsis; display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 1;/">
                                            {{data.title}}
                                         </span>
                                    </div>
                                    <div style="height: 42px;font-size: 16px;color: #999999;font-weight: 400;"  v-html="data.content"></div>
                                </div>
                                <div style="display: flex; justify-content: space-between; margin-top: 10px">
                                    <div><span style="color: #999999; margin-right: 10px">{{data.createTime.substring(0, 10)}}</span></div>
                                    <div><span v-if="data.kwTypeArrName" style="color: #031F88">{{data.kwTypeArrName[0]}}>{{data.kwTypeArrName[1]}}>{{data.kwTypeArrName[2]}}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  图片more  -->
                <div v-if="class_id == 2" >
                    <div v-for="(item,index) in ArticlesList" :key="index" style="width:352px;float: left;height: 356px;margin-left: 10px;margin-bottom: 20px;">
                        <div @click="content_info_type(item.id,item.payAmt,item.uuid,item.bgbType,item.PayStatus)" class="hand op sc" style="width: 96%;margin: 2%;height:100% ;background-color: white;border: 1px solid #E4E4E4;box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);border-radius: 8px;overflow:hidden;" >
                            <div style="width: 100%; height: 65%;overflow: hidden;cursor: pointer;position: relative;">
                                <div style="position: relative;">
                                    <img :src="item.cover" style="width:100%;height:229px;object-fit: cover">
                                </div>
                                <div v-if="item.payAmt > 0 " style="position: absolute; top: 0; left: 0;z-index: 10;">
                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/pay2.png" style="width: 80px;height: 80px;">
                                </div>
                                <div v-if="item.uuid != user.uuid">
                                    <img v-if="(item.payAmt > 0 && item.PayStatus == 1) || (item.payAmt == 0 && item.PayStatus == 0)" :src="item.cover" style="width:100%;height:229px;object-fit: cover">
                                    <img v-if="item.payAmt > 0 && item.PayStatus == 0" :src="item.cover" style="width:100%;height:229px;object-fit: cover;filter: blur(5px);">
                                </div>
                            </div>
                            <div style="width: 100%;height: auto;">
                                <div style="width: 70%;height:8%;color: #1a1a1a;float:left;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;padding: 8px 2% 0px 2%;cursor: pointer;font-weight: bold">{{item.title}}</div>
                                <div >
                                    <div v-show="item.payAmt > 0 && item.PayStatus == 0" style="font-size: 12px;line-height: 29px;color: red;">付费金额:{{item.payAmt}}(元)</div>
                                </div>
                            </div>
                            <div style="font-size: 14px;width:  96%;color: #6d737b;height:5%;margin-top: 5px;float:left;">
                                <div style="float: right;text-align:right;line-height: 20px;margin-left: 2px;">{{item.createTime}}</div>
                                <div style="float: left;line-height: 20px;"><span class="el-icon-view" style="font-size: 15px;color:#ff9800;padding-left:10px;"></span> {{item.clickCount}}</div>
                            </div>
                            <div style="width: 100%;height: calc(22% - 20px); border-top: 1px solid #CECFD0;margin-top: 10px;float:left;">
                                <div v-if=" typeof item.user == 'undefined'" >
                                    <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
                                        <img :src="item.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
                                    </div>
                                    <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.nickName }}</div>
                                </div>
                                <div v-else>
                                    <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
                                        <img :src="item.user.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
                                    </div>
                                    <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.user.nickName }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  视频more  -->
                <div v-if="class_id == 3">
                    <div v-for="(item,index) in ArticlesList" :key="index" style="width:352px;float: left;height: 356px;margin-left: 17px;margin-bottom: 20px;">
                        <div style="width: 96%;margin: 2%;height:100%;border: 1px solid #E4E4E4;box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);position: relative;" @click="content_info_type(item.id,item.payAmt,item.uuid,item.bgbType,item.PayStatus)" class="hand op sc">
                            <div style="width: 100%; height: 65%;overflow: hidden;cursor: pointer;position: relative;">
                                <div >
                                    <img :src="item.cover" style="width:100%;height:229px;object-fit: cover;">
                                </div>
                                <div v-if="item.payAmt > 0 " style="position: absolute; top: 0; left: 0;z-index: 10;">
                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/pay2.png" style="width: 80px;height: 80px;">
                                </div>
                                <div v-if="item.uuid != user.uuid">
                                    <img v-if="(item.payAmt > 0 && item.PayStatus == 1) || (item.payAmt == 0 && item.PayStatus == 0)" :src="item.cover" style="width:100%;height:229px;object-fit: cover">
                                    <img v-if="item.payAmt > 0 && item.PayStatus == 0" :src="item.cover" style="width:100%;height:229px;object-fit: cover;filter: blur(5px);">
                                </div>
                                <div style="margin-top: -145px;margin-left:135px;position: absolute;"><img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon13.png" style="width: 64px;height: 64px"></div>
                            </div>
                            <div style="width: 100%;height: auto;">
                                <div style="width: 70%;height:8%;color: #1a1a1a;float:left;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;padding: 8px 2% 0px 2%;cursor: pointer;font-weight: bold">{{item.title}}</div>
                                <div >
                                    <div v-show="item.payAmt > 0 && item.PayStatus == 0" style="font-size: 12px;line-height: 29px;color: red;">付费金额:{{item.payAmt}}(元)</div>
                                </div>
                            </div>
                            <div style="font-size: 14px;width:  96%;color: #6d737b;height:5%;margin-top:5px;float: left;">
                                <div style="float: right;text-align:right;line-height: 20px;margin-left: 2px;">{{item.createTime}}</div>
                                <div style="float: left;line-height: 20px;"><span class="el-icon-view" style="font-size: 15px;color:#ff9800;padding-left:10px;"></span> {{item.clickCount}}</div>
                            </div>
                            <div style="width: 100%;height: calc(22% - 20px); border-top: 1px solid #CECFD0;margin-top: 10px;float: left;">
                                <div v-if=" typeof item.user == 'undefined'" >
                                    <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
                                        <img :src="item.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
                                    </div>
                                    <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.nickName }}</div>
                                </div>
                                <div v-else>
                                    <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
                                        <img :src="item.user.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
                                    </div>
                                    <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.user.nickName }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NoData v-if="ArticlesList.length === 0"></NoData>
            </div>
       </div>
    </div>
</template>

<script>
import NoData from "../../components/common/NoData";
    export default {
        name:"",
        components: {
            NoData
        },
        watch: {
            //监听相同路由下参数变化的时候，从而实现异步刷新
            '$route'() {
                this.id = this.$route.params.id;
            },
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            }
        },
        data () {
            return {
                news_list:[],
                like2:"",
                page:1,
                HotactiveName:'first',
                title_name:'',

                channellist:[],
                nameId:this.$route.query.type,
                ArticlesList:[],
                Articles_imgs_type:[],

                Articles:[],
                type_name:'',
                assignList:[]

            }
        },
        props:{
            class_id:{
                type:String,
                default() {
                    return "1"
                }
            },
            type:{
                type:String,
                default() {
                    return "0"
                }
            },
        },
        created:function(){

        },
        mounted: function () {
            var that = this;
            // 获取推荐频道
            that.getChannelList();
            // 获取列表
            that.get_news_list(this.$route.query.type);

            // 跳转在顶部
            window.scrollTo(0, 0);

            window.onscroll = function(){
                //变量scrollTop是滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
                //变量windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
                //滚动条到底部的条件

                if(scrollTop+windowHeight == scrollHeight){
                    //到了这个就可以进行业务逻辑加载后台数据了
                    that.get_news_list(that.nameId || that.$route.query.type)
                }
            }

            this.$EventBus.$emit("close_im", {is_close: 0});

        },
        methods:{
            // 文章频道
            getChannelList(){
                var that = this;
                that.newApi.getChannelList({
                    channelId: that.$route.query.class_id
                }).then((res) => {
                    that.channellist = res.data
                })
            },
            // 点击切换
            nameType(id){
               this.nameId = id;
               this.page = 1;
               this.ArticlesList = []
               this.get_news_list(id);
            },
            get_news_list(id){
                var that = this;
                that.newApi.getRecommendedArticles({
                    channelId: id,
                    page:that.page,
                    pageSize:15,
                }).then((ret)=>{
                    for (var i = 0; i < ret.data.length; i++) {
                        that.ArticlesList.push(ret.data[i]);
                    }
                    that.page++;
                }).catch((err)=>{
                    console.log(err)
                })
            },
            //跳转到文章详情
            content_info(id){
                var that = this;
              if (!this.utils.toAuth(1)) {
                return false
              }
                    that.utils.a('/content/info/'+id+"/2");
            },

            // 图片 视频
            content_info_type:function(id,payAmt,uuid,bgbType,PayStatus){
                var that = this;
                that.kwId = id;
                that.clickBgbType = bgbType;
              if (!this.utils.toAuth(1)) {
                return false
              }
                    that.utils.a('/content/picture/'+id);
            },
        }
    }

</script>

<style scoped>
    .news_center_with_right_content{
        margin:0 auto;
        width: 1500px;
    }
    .comment_list{
        margin-top:10px; margin-bottom:10px;
    }
    .comment{
        width: 30px;font-size:16px;float: left;text-align: center;font-size: 14px;margin: 0 auto;font-weight: bold;color: #FF9902;
    }
    .comment.active{
        color: #49A3D7;
    }
    .ranking{
        font-size: 16px;color: #FF9902;
    }
    .ranking.active{
        font-size: 14px;color: #49A3D7;
    }

    /deep/ .el-divider--horizontal {
        margin: 0px 0px;
    }
</style>
